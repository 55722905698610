import { graphql } from 'gatsby';
import React from 'react';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hod from '../src/components/Hod/Hod';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import tt from '../src/helpers/translation';
import Layout from '../src/components/Layout/Layout';
import SEO from '../src/helpers/seo';
import logo_pa from '../src/images/logo-paw-43x34.png';
import Ragnar from '../src/components/Ragnar/Ragnar';

import './reconocimientos.scss';

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query reconocimiento($locale: String!) {
    allReconocimientoSeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allReconocimientoTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          subtitle
        }
      }
    }
    allReconocimientoTitle(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
          filters
        }
      }
    }
    allReconocimientoHeaderImage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allReconocimientoBreadcrumb(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allReconocimientoListBlock(
      filter: { lang: { eq: $locale } }
      sort: { fields: position, order: DESC }
    ) {
      edges {
        node {
          title
          description
          characteristics
          features
        }
      }
    }
    allReconocimientoRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class Reconocimiento extends React.Component {
  state = {
    filter: undefined,
    used: false,
  };

  handleDropDownChange = e => {
    this.setState({
      filter: e.target.value,
      used: true,
    });
  };

  handleResetFilter = e => {
    this.setState({
      filter: undefined,
      used: false,
    });
  };

  render() {
    const heimdallData = {
      name: this.props.data.allReconocimientoTitleBlock.edges[0].node.title,
      subtitle: this.props.data.allReconocimientoTitleBlock.edges[0].node.subtitle,
      image: {
        url: this.props.data.allReconocimientoHeaderImage.edges[0].node.localImage.childImageSharp
          .fluid,
        alt: this.props.data.allReconocimientoHeaderImage.edges[0].node.alt,
      },
    };

    const filters = this.props.data.allReconocimientoTitle.edges[0].node.filters.split(' ');

    let reconList;
    if (this.state.filter) {
      const varYear = tt('AÑO', this.props.pageContext.locale);
      if (this.state.filter === varYear) {
        reconList = this.props.data.allReconocimientoListBlock.edges;
      } else {
        reconList = this.props.data.allReconocimientoListBlock.edges.filter(
          e => e.node.features.replace(' ', '') === this.state.filter
        );
      }
    } else {
      reconList = this.props.data.allReconocimientoListBlock.edges;
    }

    const titleData = {
      title: this.props.data.allReconocimientoTitle.edges[0].node.description,
    };

    return (
      <Layout layoutFromOld 
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allReconocimientoSeoBlock.edges[0].node._0.title}
          description={this.props.data.allReconocimientoSeoBlock.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allReconocimientoRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allReconocimientoHeaderImage.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="recon-container">
              <div className="filter-general-container">
                <div className="filter-image">
                  <img src={logo_pa} alt="logo" />
                </div>
                <div className="standard-box filter-container">
                  <div className="select-container">
                    {this.state.used ? (
                      <select
                        onChange={this.handleDropDownChange}
                        name="recon"
                        form="recon-selector"
                      >
                        <option value={undefined} selected>
                          {tt('AÑO', this.props.pageContext.locale)}
                        </option>
                        {filters.map(option => (
                          <React.Fragment key={option}>
                            <option value={option}>{option}</option>
                          </React.Fragment>
                        ))}
                      </select>
                    ) : (
                      <select
                        onChange={this.handleDropDownChange}
                        name="recon"
                        form="recon-selector"
                        onClick={this.setState({ used: true })}
                      >
                        <option value={undefined} selected>
                          {tt('AÑO', this.props.pageContext.locale)}
                        </option>
                      </select>
                    )}
                  </div>
                  <div onClick={this.handleResetFilter} className="Asgard-clear">
                    {' '}
                    X {tt('Borrar filtros', this.props.pageContext.locale)}
                  </div>
                  <div className="filter-text">{`${reconList.length} ${tt(
                    'RECONOCIMIENTOS',
                    this.props.pageContext.locale
                  ).toLowerCase()}`}</div>
                </div>
              </div>
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allReconocimientoBreadcrumb.edges[0].node.name}
              />
              <div className="text-summary" dangerouslySetInnerHTML={{ __html: titleData.title }} />
              <Ragnar data={reconList} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Reconocimiento;
