/* eslint-disable no-unused-expressions */

import React from 'react';
import { injectIntl } from 'react-intl';
import tt from '../../helpers/translation';
import logo_pa from '../../images/logo-paw-43x34.png';
import './Ragnar.scss';

class Ragnar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, intl } = this.props;

    return (
      <div className="ragnar-general-container">
        {data.map(e => (
          <div className="ragnar-container">
            <div className="ragnar-logo-container">
              <div className="ragnar-image">
                <img src={logo_pa} alt="logo" />
              </div>
            </div>
            <div className="standard-box ragnar-element">
              <div className="ragnar-text-container">
                <div className="ragnar-title">{e.node.title}</div>
                <div className="ragnar-subtitle">{e.node.description}</div>
                {e.node.title.includes('WORLDOFPARKS.EU') && e.node.title.includes('(2017)') ? (
                  <div className="ragnar-link">
                    <a
                      href="https://www.worldofparks.eu/wop-award"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {tt('MÁS INFO', intl.locale).toLowerCase()}
                    </a>
                  </div>
                ) : null}
              </div>
              {e.node.characteristics ? (
                <div className="ragnar-char-container">
                  {e.node.characteristics && (
                    <div
                      className="ragnar-characteristics"
                      dangerouslySetInnerHTML={{ __html: e.node.characteristics }}
                    />
                  )}
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default injectIntl(Ragnar);
